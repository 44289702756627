import React from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "../Testimonials/testimonials.css";
import background from "./../../images/background-pattern.svg";
import "swiper/swiper-bundle.css";
SwiperCore.use([Autoplay]);
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export const Testimonials = () => {
  return (
    <div
      style={{
        backgroundColor: "#e7e7e7",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
      }}
      className={"md:px-16 md:px-8 px-8 bg-epg-lightgray pt-6"}
    >
      <h3
        className={
          "text-center justify-center text-3xl font-bold font-exo2 relative top-10"
        }
      >
        Client Testimonials
      </h3>
      <Swiper
        className={"md:py-36 w-full"}
        loop={true}
        slidesPerView={1}
        navigation
        slide
        autoplay={{ delay: 8000 }}
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        <SwiperSlide className={"w-96 "}>
          <Testimonial
            text={
              "Thank you for all your efforts when constructing the Laramba bore field power station. It was a big job, the quality of your work was outstanding and your dedication and professionalism second to none.				"
            }
            name={"Bill Blain"}
            location={"Laramba Power Field Station"}
          />
        </SwiperSlide>
        <SwiperSlide className={"w-96"}>
          <Testimonial
            text={
              "Before working with Phil I found it incredibly difficult to secure any generation professional in Australia that had the level of multi-faceted expertise and competence that EPG was able to demonstrate across mechanical, electrical and automation, this all coupled with a willingness to work on a smaller station and travel remotely."
            }
            name={"Ben Prat"}
            location={"Home Valley Station"}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

const Testimonial = (props) => {
  const { text, name, location } = props;
  return (
    <div
      className={
        "p-14 flex my-12  lg:my-24 text-center justify-center items-center flex-col md:text-2xl"
      }
    >
      <p className={"font-exo2 italic text-epg-gray"}>{text}</p>
      <p className={"font-exo2 text-lg font-bold mt-4 text-epg-gray "}>
        {name}
      </p>
      <p className={"font-exo2 text-sm  text-epg-gray"}>{location}</p>
    </div>
  );
};
