import * as React from "react";
import companyLogo from "../images/home_responsive_services.jpg";
import logo from "../images/epg_logo_white.svg";
import territory_proud from "../images/100-territory-owned.png";
import PageLayout from "../PageLayout";
import powergen from "../images/navigation-background.jpg";
import SectionLayout from "../SectionLayout";
import { Helmet } from "react-helmet";
import {Link} from "gatsby";
import {Testimonials} from "../components/Testimonials/Testimonials";
const Home = () => {
  return (
    <PageLayout
      image={powergen}
      logo={logo}
      largeTitle
      title={"E-P-G Solutions"}
      subtitle={"Your best choice in power generation"}
      navSize={"xl:h-224 h-160"}
      titleStyles={"xl:mt-48 lg:mt-36 md:mt-24 mt-24"}
    >
      <Helmet>
        <title>EPG Solutions | Power Generation for the Territory</title>
        <meta
          name="description"
          content="Keeping essential services running since 2013 through power generation, electrical, switchboards, water management, remote services and renewable energy."
        />
      </Helmet>
      <div className={"bg-epg-yellow h-5"} />
      <div className={"block lg:hidden"}>
        <div
            style={{ backgroundImage: `url(${companyLogo})` }}
            className={
              "shadow-md bg-epg-lightgray bg-center bg-cover bg-no-repeat w-full h-96"
            }
        />
      </div>
      <SectionLayout bgColor={"bg-epg-white"}>
        <div
          className={
            "grid lg:grid-cols-2  overflow-y-hidden lg:gap-10 flex items-center py-8 bg-epg-white"
          }
        >
          <div className={"w-full "}>
            <h3 className={"font-exo2 lg:text-4xl text-3xl font-bold leading-7"}>
              Responsive service and quality outcomes
            </h3>
            <p className={"font-exo2 text-md lg:leading-8 lg:text-lg pt-8 leading-7"}>
              Founded in 2014, E-P-G Solutions specialises in power generation
              and industrial installations across the Northern Territory.
              Focussed on providing exceptional service for government
              utilities, power generation businesses and essential public
              infrastructure, we are small enough to be responsive but large
              enough to help you meet your project goals.
            </p>
            <p className={"font-exo2 text-md lg:leading-8 lg:text-lg pt-4 leading-7"}>
              We’ve worked with some of the biggest names in the industry and
              provide peace of mind through quality craftsmanship, 24-7 call
              outs, and tailored design of automated systems to keep essential
              services running.
            </p>
            <Link to={"/services"}>
            <button
              className={
                "mt-8 justify-start focus:outline-none bg-epg-yellow hover:bg-epg-white px-6 h-12 lg:h-16  font-exo2 text-xl font-bold rounded-md self-start"
              }
              type="button"
            >
              See what we offer
            </button>
            </Link>
          </div>
          <div className={"lg:block hidden"}>
            <div
              style={{ backgroundImage: `url(${companyLogo})` }}
              className={
                "shadow-lg bg-epg-lightgray bg-center bg-cover bg-no-repeat h-144 w-full"
              }
            />
          </div>
        </div>
      </SectionLayout>
      <SectionLayout bgColor={"bg-epg-lightgray"}>
        <div
          className={
            "bg-epg-lightgray grid lg:grid-cols-2 lg:gap-10 w-full py-8 flex justify-center items-center"
          }
        >
          <img
            className={
              "p-6 w-112 mx-auto object-cover object-cover  hidden md:block"
            }
            src={territory_proud}
            alt="BigCo Inc. logo"
          />
          <div className={{}}>
            <h3
              className={"font-exo2 lg:text-4xl text-3xl font-bold  md:pt-8 leading-7"}
            >
              100% Territory owned and operated
            </h3>
            <p className={"font-exo2 text-md lg:leading-8 lg:text-lg pt-8 leading-7"}>
              E-P-G Solutions is proudly Territorian and we buy and support
              local whenever we can. We have great relationships with our
              contractors, clients and suppliers. (E-P-G Solutions see our
              suppliers as an essential part of our team.) We work hard to
              exceed our customer’s expectations. The job is complete only when
              you are satisfied.
            </p>
            <p className={"font-exo2 text-md lg:leading-8 lg:text-lg pt-4 leading-7"}>
              We have more than 30 years’ experience in the industry, but we
              won’t pretend to know everything. If we don’t know the answer, we
              will move mountains to find it. We’re committed to great outcomes
              and happy customers.
            </p>
            <Link to={"/previous-projects"}>
            <button
              className={
                " mt-8 mb-12 bg-epg-yellow focus:outline-none hover:bg-epg-white px-6 h-12 lg:h-16 font-exo2 text-xl font-bold rounded-md self-center"
              }
              type="button"
            >
              Check out our projects
            </button>
            </Link>
          </div>
          <img
            className={
              "p-6 w-112 mx-auto object-cover object-cover md:hidden block"
            }
            src={territory_proud}
            alt="BigCo Inc. logo"
          />
        </div>
      </SectionLayout>
      <Testimonials/>
    </PageLayout>
  );
};

export default Home;
